import Form from '@/components/Form'
import { React } from '@/vendor'
import * as yup from 'yup'

const initialValues = {
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	rent: '',
	source: '',
	comments: '',
	gcaptcha: '',
}

const validationSchema = yup.object({
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	phone: yup.string().length(14).required(),
	email: yup.string().email().required(),
	rent: yup.string().required(),
	source: yup.string().required(),
	comments: yup.string().required(),
	gcaptcha: yup.string().required(),
})

const outboundEmail = {
	subject: 'New contact request',
	body: (values) => (
		<>
			<strong>First Name:</strong> {values.firstName}
			<br />
			<strong>Last Name:</strong> {values.lastName}
			<br />
			<strong>Phone:</strong> {values.phone}
			<br />
			<strong>Email:</strong> {values.email}
			<br />
			<strong>Looking to rent:</strong> {values.rent}
			<br />
			<strong>Source:</strong> {values.source}
			<br />
			<strong>Comments:</strong>
			<br />
			{values.comments}
		</>
	),
}

function ContactForm() {
	return (
		<Form
			initialValues={initialValues}
			validationSchema={validationSchema}
			outboundEmail={outboundEmail}
			submitButtonSecondary
		>
			<Form.Row>
				<Form.Field label="First Name" as={<Form.Input type="text" name="firstName" />} />
				<Form.Field label="Last Name" as={<Form.Input type="text" name="lastName" />} />
			</Form.Row>
			<Form.Row>
				<Form.Field label="Phone" as={<Form.Input.Phone name="phone" />} />
				<Form.Field label="Email" as={<Form.Input type="text" name="email" />} />
			</Form.Row>
			<Form.Row>
				<Form.Field
					label="Are you looking to rent?"
					as={<Form.Select name="rent" options={['Yes', 'No', 'Possibly']} />}
				/>
				<Form.Field
					label="How did you hear about us?"
					as={
						<Form.Select
							name="source"
							options={[
								'Resident Referral',
								'Internet',
								'Rental Magazine',
								'Drive By',
								'Rental Service',
								'Other',
							]}
						/>
					}
				/>
			</Form.Row>
			<Form.Row>
				<Form.Field label="Comments" as={<Form.Textarea name="comments" />} />
			</Form.Row>
			<Form.ReCAPTCHA name="gcaptcha" dark />
		</Form>
	)
}

export default ContactForm
