import { React, Classnames } from '@/vendor'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import styles from './styles.module.scss'

function Gallery({ data, className }) {
	return (
		<SimpleReactLightbox>
			<SRLWrapper>
				<div className={Classnames(styles.gallery, className)}>
					{data.map((image, idx) => (
						<div className={styles.gallery__cell} key={idx}>
							<img src={image.thumb} width="415" height="415" />
						</div>
					))}
				</div>
			</SRLWrapper>
		</SimpleReactLightbox>
	)
}

export default Gallery
