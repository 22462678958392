import Button from '@/components/Button'
import { useCraft } from '@/components/Craft'
import Fs from '@/components/Fs'
import Grid from '@/components/Grid'
import Sprite from '@/components/Sprite'
import { React } from '@/vendor'
import axios from 'axios'
import Cleave from 'cleave.js/react'
import { Formik, Form as FormikForm, useField } from 'formik'
import ReactDOMServer from 'react-dom/server'
import ReCAPTCHA from 'react-google-recaptcha'
import { useToggle } from 'react-use'
import { Dialog } from './Dialog'
import styles from './styles.module.scss'

function Form({ children, submitButtonPrimary, submitButtonSecondary, outboundEmail, ...props }) {
	const { settings } = useCraft()
	const [dialogActive, toggleDialog] = useToggle(false)

	const onSubmit = async (allValues) => {
		if (outboundEmail) {
			const { gcaptcha, ...values } = allValues
			axios.post(
				'/api/sendmail',
				{
					to: settings.propertyEmailAddress,
					subject: outboundEmail.subject,
					message: ReactDOMServer.renderToStaticMarkup(outboundEmail.body(values)),
				},
				{
					headers: {
						'x-captcha': gcaptcha,
					},
				},
			)
		}
		toggleDialog(true)
	}
	return (
		<>
			<Dialog
				in={dialogActive}
				onClose={() => {
					toggleDialog(false)
				}}
			/>
			<Formik validateOnMount onSubmit={onSubmit} {...props}>
				{(formik) => {
					return (
						<FormikForm className={styles.form}>
							<Grid stacked={true}>{children}</Grid>
							<div className={styles.form__submit}>
								<Button type="submit" primary={submitButtonPrimary} secondary={submitButtonSecondary}>
									Submit
								</Button>
							</div>
						</FormikForm>
					)
				}}
			</Formik>
		</>
	)
}

Form.Masthead = ({ headline, children, ...props }) => (
	<Grid.Cell className={styles.form__masthead}>
		<Fs {...props}>
			{typeof headline === 'string' ? <Fs variant="fs3">{headline}</Fs> : headline}
			{children}
		</Fs>
	</Grid.Cell>
)

Form.Row = ({ children }) => (
	<Grid.Cell>
		<Grid>
			{React.Children.map(children, (child) => (
				<Grid.Cell>{child}</Grid.Cell>
			))}
		</Grid>
	</Grid.Cell>
)

Form.Field = ({ label, as }) => {
	const [, { touched, error }] = useField(as.props.name)
	return (
		<div className={styles.field}>
			<label>
				<div className={styles.field__label}>{label}</div>
				<div className={styles.field__input}>{as}</div>
			</label>
			{touched && error ? <div className={styles.field__error}>This field is required.</div> : null}
		</div>
	)
}

Form.Input = ({ name, options = {}, raw = false, ...props }) => {
	const [field, , { setValue }] = useField(name)
	return (
		<Cleave
			options={options}
			{...field}
			onChange={(e) => {
				const value = raw ? e.target.rawValue : e.target.value
				setValue(value)
			}}
			{...props}
		/>
	)
}

Form.Input.Phone = (props) => (
	<Form.Input
		type="tel"
		options={{
			numericOnly: true,
			delimiters: ['(', ') ', '-'],
			blocks: [0, 3, 3, 4],
		}}
		{...props}
	/>
)

Form.Select = ({ options, ...props }) => {
	const [field, { value }] = useField(props.name)
	const outputOptions = [
		{ value: '', label: 'Select one' },
		...options.map((option) => {
			if (typeof option === 'string') {
				option = { value: option, label: option }
			}
			return option
		}),
	]
	const active = outputOptions.find((option) => option.value === value)
	return (
		<div className={styles.select}>
			<div className={styles.select__inner}>
				{active?.label}
				<Sprite name="notch" />
			</div>
			<select {...field} {...props}>
				{outputOptions.map((option) => (
					<option value={option.value} key={`${option.value}-${option.label}`}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}

Form.Textarea = (props) => {
	const [field] = useField(props.name)
	return <textarea {...field} {...props} />
}

Form.ReCAPTCHA = (props) => {
	const [field, { touched, error }, { setValue }] = useField(props.name)
	return (
		<Grid.Cell>
			<div className={styles.recaptcha}>
				<ReCAPTCHA
					sitekey="6Lf6ijspAAAAAAdygkAZ-rICW0UFTr3386OJKb3R"
					onChange={(token) => {
						setValue(token)
					}}
					theme={props.dark ? 'dark' : 'light'}
				/>
				{touched && error ? <div className={styles.field__error}>This field is required.</div> : null}
			</div>
		</Grid.Cell>
	)
}

export default Form
