import AppWrapper from '@/components/AppWrapper'
import ContactForm from '@/components/ContactForm'
import { useCraft } from '@/components/Craft'
import Fs from '@/components/Fs'
import Grid from '@/components/Grid'
import { useMq } from '@/components/Mq'
import Nl2br from '@/components/Nl2br'
import SlideUpDown from '@/components/SlideUpDown'
import Sprite from '@/components/Sprite'
import TelLink from '@/components/TelLink'
import usePageChangeListener from '@/components/usePageChangeListener'
import { Classnames, React } from '@/vendor'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import styles from './styles.module.scss'

const ContactToggler = ({ fixedContact, contactActive, children, onClick }) => {
	const config = fixedContact
		? {
				tag: 'div',
				attrs: {
					children,
				},
		  }
		: {
				tag: 'button',
				attrs: {
					type: 'button',
					onClick,
					children: (
						<>
							{children}
							<Sprite name="notch" rotate={contactActive ? 180 : undefined} className={styles.notch} />
						</>
					),
				},
		  }
	return <Fs tag={config.tag} variant="fs3" className={styles.contact__headline} {...config.attrs} />
}

const FooterFs = (props) => {
	const mq = useMq()
	return <Fs align={!mq.large ? 'center' : null} {...props} />
}

const Footer = ({ badge, fixedContact }) => {
	const { settings } = useCraft()
	const [contactActive, toggleContactActive] = useToggle(false)
	const mq = useMq()
	usePageChangeListener(() => {
		toggleContactActive(false)
	})
	React.useLayoutEffect(() => {
		toggleContactActive(fixedContact)
	}, [fixedContact])
	return (
		<div className={styles.footer}>
			<div
				className={Classnames(styles.contact, {
					[styles.active]: contactActive,
				})}
			>
				<AppWrapper.Container>
					<ContactToggler
						fixedContact={fixedContact}
						contactActive={contactActive}
						onClick={toggleContactActive}
						children="Contact us about inquiries or questions"
					/>
					<SlideUpDown active={contactActive} duration={fixedContact ? 0 : 300}>
						<div className={styles.formWrapper}>
							<ContactForm />
						</div>
					</SlideUpDown>
				</AppWrapper.Container>
			</div>
			<div className={styles.inner}>
				<AppWrapper.Container>
					<Grid stacked={!mq.large}>
						<Grid.Cell>
							{React.cloneElement(badge, {
								className: styles.badge,
							})}
						</Grid.Cell>
						<Grid.Cell flex={3}>
							<Grid stacked={!mq.large}>
								<Grid.Cell flex={3}>
									<FooterFs>
										<p>
											{settings.propertyName}
											<br />
											<Nl2br text={settings.propertyAddress} />
											<Nl2br text={settings.propertyOperationHours} />
										</p>
									</FooterFs>
								</Grid.Cell>
								<Grid.Cell flex={2}>
									<FooterFs className={styles.nav}>
										<Link to="/apartments">Apartments</Link>
										<Link to="/residents">Residents</Link>
										<Link to="/apartments/neighborhood">Neighborhood</Link>
										<Link to="/contact">Contact</Link>
									</FooterFs>
								</Grid.Cell>
								<Grid.Cell flex={2}>
									<FooterFs>
										<p>
											Schedule a Showing
											<br />
											T. <TelLink number={settings.propertyPhoneNumber} />
											<br />
											F. {settings.propertyFaxNumber}
											<br />
											<a href={`mailto:${settings.propertyEmailAddress}`}>
												{settings.propertyEmailAddress}
											</a>
										</p>
									</FooterFs>
								</Grid.Cell>
								<Grid.Cell flex={1}>
									<FooterFs className={Classnames(styles.nav, styles.social)}>
										{['twitter', 'facebook', 'youtube', 'pinterest'].map((name) => {
											const url = settings[`${name}Url`]
											return url ? (
												<a href={url} key={name} target="_blank">
													<Sprite name={name} />
												</a>
											) : null
										})}
									</FooterFs>
								</Grid.Cell>
							</Grid>
							<FooterFs className={styles.copyright}>
								© {new Date().getFullYear()} {settings.propertyName}
							</FooterFs>
						</Grid.Cell>
					</Grid>
				</AppWrapper.Container>
			</div>
		</div>
	)
}

export default Footer
