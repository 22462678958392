import { React, PropTypes } from '@/vendor'
import { ApolloClient, InMemoryCache } from '@apollo/client'

const Context = React.createContext()

const Craft = (props) => <Context.Consumer {...props} />

Craft.Context = Context

Craft.Provider = ({ apiUrl, query, variables, serialize, children }) => {
	const [state, setState] = React.useState({ loading: true })
	React.useEffect(async () => {
		const client = new ApolloClient({
			uri: apiUrl,
			cache: new InMemoryCache(),
		})
		const response = await client.query({
			query,
			variables,
		})
		const data = serialize(response.data)
		setState({
			loading: false,
			...data,
		})
	}, [])
	return <Context.Provider value={state} children={children(state)} />
}
Craft.Provider.defaultProps = {
	variables: {},
	serialize: (data) => data,
}
Craft.Provider.propTypes = {
	apiUrl: PropTypes.string.isRequired,
	query: PropTypes.object.isRequired,
	variables: PropTypes.object,
	serialize: PropTypes.func,
	children: PropTypes.func,
}

Craft.Logger = ({ enabled }) => {
	if (enabled) {
		const context = React.useContext(Context)
		console.log(`CRAFT:`, context)
	}
	return null
}

Craft.Img = ({ url, width, height, ...props }) => {
	delete props.__typename
	return <img src={url} width={width / 2} height={height / 2} {...props} />
}

Craft.Img.propTypes = {
	url: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
}

Craft.RichText = ({ html, ...props }) => <div dangerouslySetInnerHTML={{ __html: html }} {...props} />

Craft.RichText.propTypes = {
	html: PropTypes.string.isRequired,
}

export function useCraft() {
	return React.useContext(Context)
}

export default Craft
