import { Classnames, Helmet, React } from '@/vendor'
// import { useLockBodyScroll } from 'react-use'
import { get, initial, last } from 'lodash'
import { Link } from 'react-router-dom'
import ApplyForm from '../ApplyForm'
import Burger from '../Burger'
import CSSTransition from '../CSSTransition'
import Craft, { useCraft } from '../Craft'
import Fs from '../Fs'
import Grid from '../Grid'
import { useMq } from '../Mq'
import Nl2br from '../Nl2br'
import ScrollToTop from '../ScrollToTop'
import Sprite from '../Sprite'
import TelLink from '../TelLink'
import usePageChangeListener from '../usePageChangeListener'
import Footer from './Footer'
import styles from './styles.module.scss'

function generateGenericContainer(baseClassName) {
	return ({ className, ...props }) => <div className={Classnames(baseClassName, className)} {...props} />
}

const Context = React.createContext()

const AppWrapper = ({ pageTitle, children }) => {
	const mq = useMq()
	const [navActive, setNavActive] = React.useState(false)
	const [fixedContact, setFixedContact] = React.useState(false)
	const [applyFormId, setApplyFormId] = React.useState(null)
	// useLockBodyScroll(!!applyFormId || navActive)
	usePageChangeListener(() => {
		setNavActive(false)
	})
	const { settings } = useCraft()
	const api = {
		setFixedContact,
		setApplyFormId,
	}
	return (
		<Context.Provider value={api}>
			{pageTitle && (
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			)}
			<div className={styles.app}>
				<CSSTransition
					in={navActive}
					classNames={{
						enter: styles.enter,
						enterActive: styles.enterActive,
						exitActive: styles.exitActive,
					}}
					children={
						<div className={styles.overlayMenu}>
							<AppWrapper.Container>
								<div
									className={Classnames(styles.menu__inner, {
										[styles.float]: mq.large,
									})}
								>
									<ul className={styles.menu__links}>
										<li>
											<Link to="/apartments">Apartments</Link>
										</li>
										<li>
											<Link to="/residents">Residents</Link>
										</li>
										<li>
											<Link to="/contact">Contact</Link>
										</li>
										<li>
											<a href={settings.residentPortalUrl} target="_blank">
												Resident Portal
											</a>
										</li>
									</ul>
									<Grid>
										<Grid.Cell flex={1.5}>
											<Fs>
												<Nl2br text={settings.propertyAddress} />
												<Nl2br text={settings.propertyOperationHours} />
											</Fs>
										</Grid.Cell>
										<Grid.Cell>
											<Fs>
												Schedule a Showing
												<br />
												T. <TelLink number={settings.propertyPhoneNumber} />
												<br />
												F. {settings.propertyFaxNumber}
												<br />
												<a href={`mailto:${settings.propertyEmailAddress}`}>
													{settings.propertyEmailAddress}
												</a>
											</Fs>
										</Grid.Cell>
									</Grid>
								</div>
							</AppWrapper.Container>
						</div>
					}
				/>
				<CSSTransition
					in={!!applyFormId}
					classNames={{
						enter: styles.enter,
						exit: styles.exit,
						enterActive: styles.enterActive,
						exitActive: styles.exitActive,
					}}
					children={
						<div className={styles.applyFormOverlay}>
							<button
								onClick={() => {
									setApplyFormId(null)
								}}
								className={styles.close}
							>
								<Sprite name="close" />
							</button>
							<Fs>
								<Fs variant="fs2" tag="h2">
									Apply Now
								</Fs>
								<p>
									An application fee of $40.00 per person (non-refundable) is due when an application
									for an apartment is submitted. Security Deposit of $500.00 is due in order to hold
									an apartment, or when an application is submitted.
								</p>
							</Fs>
							<div className={styles.form}>
								<ApplyForm id={applyFormId} />
							</div>
						</div>
					}
				/>
				<div className={styles.top}>
					<div className={styles.header}>
						<AppWrapper.Container className={styles.inner}>
							<Link to="/">
								<Craft.Img {...settings.logo[0]} />
							</Link>
							<div className={styles.header__right}>
								{!mq.small && (
									<>
										<div className={styles.quickLink}>
											<Sprite name="phone" />
											<TelLink number={settings.propertyPhoneNumber} />
										</div>
										<div className={styles.quickLink}>
											<Sprite name="lock" />
											<a href={settings.residentPortalUrl} target="_blank">
												Resident Portal
											</a>
										</div>
									</>
								)}
								<Burger
									active={navActive}
									onClick={() => {
										setNavActive(!navActive)
									}}
								/>
							</div>
						</AppWrapper.Container>
					</div>
					{children}
				</div>
				<div className={styles.bottom}>
					<Footer badge={<Craft.Img {...settings.badgeLogo[0]} />} fixedContact={fixedContact} />
				</div>
			</div>
		</Context.Provider>
	)
}

AppWrapper.Context = Context

AppWrapper.useContext = () => React.useContext(Context)

AppWrapper.Masthead = ({ className, bgSrc, dark = false, mask, ...props }) => {
	const { settings } = React.useContext(Craft.Context)
	const backgroundImageUrl = bgSrc || get(settings, `mastheadBackground${dark ? 1 : 2}[0].url`)
	const classNames = Classnames(styles.masthead, className, dark ? styles.dark : styles.basic, {
		[styles.noMask]: mask === false,
	})
	return (
		<div
			className={classNames}
			style={{
				backgroundImage: `url('${backgroundImageUrl}')`,
			}}
			{...props}
		/>
	)
}

AppWrapper.Masthead.Basic = ({ headline }) => {
	const words = headline.split(' ')
	const lhs = initial(words).join(' ')
	const rhs = last(words)
	return (
		<AppWrapper.Container>
			<Fs tag="h1" variant="fs1">
				{lhs}
				{rhs && (
					<>
						<br />
						{rhs}
					</>
				)}
			</Fs>
		</AppWrapper.Container>
	)
}
AppWrapper.Masthead.Nav = generateGenericContainer(styles.mastheadNav)
AppWrapper.Body = generateGenericContainer(styles.body)

AppWrapper.Banner = ({ children }) => {
	const mq = useMq()
	return (
		<div className={styles.banner}>
			<AppWrapper.Container>{children}</AppWrapper.Container>
		</div>
	)
}

AppWrapper.Container = ({ children, className, slim = false }) => {
	const classNames = Classnames(styles.container, className, {
		[styles.slim]: slim,
	})
	return <div className={classNames}>{children}</div>
}

AppWrapper.Section = ({ children }) => {
	return <div className={styles.section}>{children}</div>
}

AppWrapper.FixedContact = () => {
	const wrapper = React.useContext(Context)
	React.useEffect(() => {
		wrapper.setFixedContact(true)
		return () => {
			wrapper.setFixedContact(false)
		}
	}, [])
	return null
}

AppWrapper.ScrollToTop = ScrollToTop

export default AppWrapper
