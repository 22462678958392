import { React } from '@/vendor'
import Velocity from 'velocity-animate'

function ScrollToTop() {
	React.useEffect(() => {
		Velocity(document.body, 'scroll', {
			duration: 300,
			easing: 'easeInOutExpo',
		})
	}, [])
	return null
}

export default ScrollToTop
