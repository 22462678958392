import { AppWrapper, ServiceRequestForm, TwoColGrid } from '@/components'
import { useCraft } from '@/components/Craft'
import TelLink from '@/components/TelLink'
import { Helmet, React } from '@/vendor'

export default () => {
	const { settings } = useCraft()
	return (
		<>
			<Helmet>
				<title>For Residents</title>
			</Helmet>
			<AppWrapper.ScrollToTop />
			<AppWrapper.Masthead>
				<AppWrapper.Masthead.Basic headline="For Residents" />
			</AppWrapper.Masthead>
			<AppWrapper.Banner>
				<TwoColGrid
					lhs={
						<TwoColGrid.HeadlineText headline="Rent-Referal Program">
							Rental season is in full swing. If you recommend {settings.propertyName} to a qualified
							renter that signs a lease, it is worth $1,000 as a rent credit!
						</TwoColGrid.HeadlineText>
					}
					rhs={
						<TwoColGrid.HeadlineText headline="For Emergencies">
							For maintenance emergencies involving large amounts of water leakage, electricity problems,
							and issues causing safety hazards, please call{' '}
							<TelLink number={settings.propertyPhoneNumber} />.
						</TwoColGrid.HeadlineText>
					}
				/>
			</AppWrapper.Banner>
			<AppWrapper.Body>
				<AppWrapper.Container>
					<ServiceRequestForm />
				</AppWrapper.Container>
			</AppWrapper.Body>
		</>
	)
}
