import { React, PropTypes, Classnames } from '@/vendor'
import styles from './styles.module.scss'

const Burger = ({ active = false, className, ...props }) => (
	<button
		className={Classnames(className, styles['hamburger'], styles['hamburger--spin'], {
			[styles['is-active']]: active,
		})}
		type="button"
		aria-label="Menu"
		aria-controls="navigation"
		children={
			<span className={styles['hamburger-box']}>
				<span className={styles['hamburger-inner']} />
			</span>
		}
		{...props}
	/>
)

Burger.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
}

export default Burger
