import { React, Classnames } from '@/vendor'
import { useMq } from '@/components/Mq'
import { useCss } from 'react-use'
import useVr from '@/components/useVr'
import styles from './styles.module.scss'

const Grid = ({ className, stacked, vr = [16, 16], ...props }) => {
	const mq = useMq()
	const vrClassName = useVr.kids(vr[0], vr[1])
	const isStacked = stacked === undefined ? mq.small : stacked
	const classNames = Classnames(styles.grid, className, isStacked && Classnames(styles.stacked, vrClassName))
	return <div className={classNames} stacked={isStacked ? '' : null} inline={!isStacked ? '' : null} {...props} />
}

Grid.Cell = ({ className, flex = 1, ...props }) => {
	const flexClassName = useCss({
		flex: flex === 0 ? '0 0 auto' : flex,
	})
	return <div className={Classnames(styles.cell, flexClassName, className)} {...props} />
}

export default Grid
