import Form from '@/components/Form'
import { React } from '@/vendor'
import * as yup from 'yup'

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	apartmentNumber: '',
	phone: '',
	requestType: '',
	comments: '',
	gcaptcha: '',
}

const validationSchema = yup.object({
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	email: yup.string().email().required(),
	apartmentNumber: yup.string().required(),
	phone: yup.string().length(14).required(),
	requestType: yup.string().required(),
	comments: yup.string().required(),
	gcaptcha: yup.string().required(),
})

const outboundEmail = {
	subject: 'New service request',
	body: (values) => (
		<>
			<strong>First Name:</strong> {values.firstName}
			<br />
			<strong>Last Name:</strong> {values.lastName}
			<br />
			<strong>Email:</strong> {values.email}
			<br />
			<strong>Apartment Number:</strong> {values.apartmentNumber}
			<br />
			<strong>Phone:</strong> {values.phone}
			<br />
			<strong>Request Type:</strong> {values.requestType}
			<br />
			<strong>Comments:</strong>
			<br />
			{values.comments}
		</>
	),
}

function ServiceRequestForm() {
	return (
		<Form
			initialValues={initialValues}
			validationSchema={validationSchema}
			outboundEmail={outboundEmail}
			submitButtonPrimary
		>
			<Form.Masthead
				headline="Service Requests"
				children="Complete the form below to enter a service or repair issue in your unit"
				align="center"
			/>
			<Form.Row>
				<Form.Field label="First Name" as={<Form.Input type="text" name="firstName" />} />
				<Form.Field label="Last Name" as={<Form.Input type="text" name="lastName" />} />
			</Form.Row>
			<Form.Row>
				<Form.Field label="Email" as={<Form.Input type="text" name="email" />} />
				<Form.Field label="Apartment Number" as={<Form.Input type="text" name="apartmentNumber" />} />
			</Form.Row>
			<Form.Row>
				<Form.Field label="Phone" as={<Form.Input.Phone name="phone" />} />
				<Form.Field
					label="Request Type"
					as={
						<Form.Select
							name="requestType"
							options={['Kitchen', 'Dining Room', 'Living Room', 'Deck / Patio', 'Bedroom', 'Bathroom']}
						/>
					}
				/>
			</Form.Row>
			<Form.Row>
				<Form.Field label="Comments" as={<Form.Textarea name="comments" />} />
			</Form.Row>
			<Form.ReCAPTCHA name="gcaptcha" />
		</Form>
	)
}

export default ServiceRequestForm
