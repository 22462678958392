import { React, PropTypes } from '@/vendor'
import Fs from '../Fs'

function RichText({ children, ...props }) {
	if (typeof children === 'string') {
		props.dangerouslySetInnerHTML = {
			__html: children,
		}
	} else {
		props.children = children
	}
	return <Fs {...props} />
}

RichText.propTypes = {
	children: PropTypes.node,
}

export default RichText
