import { React } from '@/vendor'
import AppWrapper from '@/components/AppWrapper'
import Craft, { useCraft } from '@/components/Craft'
import Fs from '@/components/Fs'
import Nl2br from '@/components/Nl2br'
import ImageTextGrid from '@/components/ImageTextGrid'

function Neighborhood() {
	const { neighborhood } = useCraft()
	return (
		<AppWrapper.Body>
			<AppWrapper.Section>
				<AppWrapper.Container slim>
					<Fs variant="fs4" align="center">
						<Craft.RichText html={neighborhood.intro} />
					</Fs>
				</AppWrapper.Container>
			</AppWrapper.Section>
			<AppWrapper.Section>
				<AppWrapper.Container>
					<ImageTextGrid data={neighborhood.imageTextGrid} />
				</AppWrapper.Container>
			</AppWrapper.Section>
		</AppWrapper.Body>
	)
}

export default Neighborhood
