import { React } from '@/vendor'
import { Transition } from 'react-transition-group'
import Velocity from 'velocity-animate'

const SlideUpDown = ({ active, duration = 300, ...props }) => {
	const [promise, setPromise] = React.useState(null)
	const transition = (node, entering) => {
		const effect = entering ? 'slideDown' : 'slideUp'
		const promise = Velocity(node, effect, {
			duration,
		})
		setPromise(promise)
	}
	const onEnter = (node) => {
		transition(node, true)
	}
	const onExit = (node) => {
		transition(node, false)
	}
	const endListener = async (node, done) => {
		if (promise) {
			await promise
		}
		done()
	}
	return (
		<Transition
			in={active}
			onEnter={onEnter}
			onExit={onExit}
			addEndListener={endListener}
			mountOnEnter
			unmountOnExit
			{...props}
		/>
	)
}

export default SlideUpDown

// import React from 'react'
// import PropTypes from 'prop-types'
// import styles from './styles.module.scss'
// import AppStore from '@/store/app'
// import { Transition } from 'react-transition-group'
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
// import Velocity from 'velocity-animate'
// import Logo from '@/components/Logo'
// import { withRouter } from 'react-router'
// import { NavLink } from 'react-router-dom'
// import { Container } from '@/components/common'
// import withCraftEntries from '@/components/withCraftEntries'
//
// function CloseIcon () {
// 	return (
// 		<svg width="27px" height="27px" viewBox='0 0 27 27' version='1.1'>
// 			<g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
// 				<g id='mobile-nav' transform='translate(-274.000000, -23.000000)' fill='#FFFFFF' fillRule='nonzero'>
// 					<path d='M278.12643,49.450213 L299.958352,27.6182912 C300.422123,27.1545203 300.422123,26.4026835 299.958352,25.9389126 L297.859129,23.8396893 C297.395358,23.3759184 296.643521,23.3759184 296.17975,23.8396893 L274.347828,45.6716112 C273.884057,46.1353821 273.884057,46.8872189 274.347828,47.3509898 L276.447051,49.450213 C276.910822,49.9139839 277.662659,49.9139839 278.12643,49.450213 Z M274.347828,27.8214162 L296.17975,49.653338 C296.643521,50.1171089 297.395358,50.1171089 297.859129,49.653338 L299.958352,47.5541148 C300.422123,47.0903439 300.422123,46.3385071 299.958352,45.8747362 L278.12643,24.0428143 C277.662659,23.5790434 276.910822,23.5790434 276.447051,24.0428143 L274.347828,26.1420376 C273.884057,26.6058085 273.884057,27.3576453 274.347828,27.8214162 Z' id='close'></path>
// 				</g>
// 			</g>
// 		</svg>
// 	)
// }
//
// class OverlayNav extends React.PureComponent {
//
// 	static defaultProps = {
// 		categories: []
// 	}
//
// 	static propTypes = {
// 		location: PropTypes.object.isRequired,
// 		categories: PropTypes.array
// 	}
//
// 	componentDidUpdate (prevProps) {
// 		if(this.props.location.pathname !== prevProps.location.pathname) {
// 			this.closeNav()
// 		}
// 	}
//
// 	transition (node, entering) {
// 		const effect = (entering) ? 'slideDown' : 'slideUp'
// 		this.setState({
// 			transitionPromise: Velocity(node, effect, {
// 				duration: 300
// 			})
// 		})
// 	}
//
// 	onEnter (node) {
// 		disableBodyScroll(node)
// 		this.transition(node, true)
// 	}
//
// 	onExit (node) {
// 		enableBodyScroll(node)
// 		this.transition(node, false)
// 	}
//
// 	async endListener (node, done) {
// 		await this.state.transitionPromise
// 		done()
// 	}
//
// 	closeNav () {
// 		AppStore.hideOverlayNav()
// 	}
//
// 	get navItems () {
// 		return [
// 			{ to: '/our-firm', title: 'Our Firm' },
// 			{ to: '/our-team', title: 'Our Team' },
// 			{ to: '/practices', title: 'Practices' },
// 			{ to: '/locations', title: 'Locations' },
// 			{
// 				to: '/news-insights',
// 				title: 'News & Insights',
// 				children: this.props.categories.map((category) => ({
// 					to: `/news-insights/?category=${category.slug}`, title: category.title
// 				}))
// 			},
// 			{ to: '/careers', title: 'Careers' }
// 		]
// 	}
//
// 	render () {
// 		return (
// 			<Transition in={AppStore.overlayNavActive} onEnter={::this.onEnter} onExit={::this.onExit} addEndListener={::this.endListener} mountOnEnter unmountOnExit>
// 				<div className={styles.wrapper}>
// 					<Container>
// 						<div className={styles.header}>
// 							<Logo inverse={true} />
// 							<button onClick={::this.closeNav} className={styles.closeBtn}><CloseIcon /></button>
// 						</div>
// 						<ul className={styles.nav}>
// 							{this.navItems.map((link) => (
// 								<li key={link.to}>
// 									<NavLink to={link.to} onClick={::this.closeNav}>{link.title}</NavLink>
// 									{link.children && (
// 										<ul>
// 											{link.children.map((child) => (
// 												<li key={child.to}>
// 													<NavLink to={child.to} onClick={::this.closeNav}>{child.title}</NavLink>
// 												</li>
// 											))}
// 										</ul>
// 									)}
// 								</li>
// 							))}
// 						</ul>
// 					</Container>
// 				</div>
// 			</Transition>
// 		)
// 	}
//
// }
//
// export default withCraftEntries(withRouter(OverlayNav), {
// 	categories: {
// 		query: require('@/craft/queries/articleCategories.gql')
// 	}
// })
