import { React, Classnames } from '@/vendor'
import { useCss } from 'react-use'
import Craft from '@/components/Craft'
import Grid from '@/components/Grid'
import Fs from '@/components/Fs'
import Button from '@/components/Button'
import styles from './styles.module.scss'

function Row({ idx, headline, text, image, ctaLabel, ctaLink }) {
	const alt = idx % 2 > 0
	return (
		<Grid.Cell>
			<Grid className={Classnames(styles.row, { [styles.alt]: alt })}>
				<Grid.Cell className={styles.image}>
					<div className={styles.shadow}>
						<Craft.Img {...image[0]} />
					</div>
				</Grid.Cell>
				<Grid.Cell className={styles.text}>
					<Fs className={styles.text__block}>
						<Fs tag="h2" variant="fs2">
							{headline}
						</Fs>
						<Craft.RichText html={text} />
						{ctaLabel && ctaLink && (
							<div className={styles.cta}>
								<Button.Link to={ctaLink} primary>
									{ctaLabel}
								</Button.Link>
							</div>
						)}
					</Fs>
				</Grid.Cell>
			</Grid>
		</Grid.Cell>
	)
}

function ImageTextGrid({ data }) {
	return (
		<Grid stacked className={styles.outer} vr={[48, 96]}>
			{data.map((row, idx) => (
				<Row {...row} idx={idx} key={row.id} />
			))}
		</Grid>
	)
}

export default ImageTextGrid
