import { useMq } from '@/components/Mq'
import { useCss } from 'react-use'

const SCREEN_MIN = 767
const SCREEN_MAX = 1000
const SCREEN_DIFF = SCREEN_MAX - SCREEN_MIN

function generateCss(min, max, unit = 'marginTop') {
	const mq = useMq()
	if (mq.small) {
		return {
			[unit]: `${min}px`,
		}
	}
	if (mq.medium) {
		const minMaxDiff = max - min
		return {
			[`@media (min-width: ${SCREEN_MIN}px) and (max-width: ${SCREEN_MAX}px)`]: {
				[unit]: `calc(${min}px + (((100vw - ${SCREEN_MIN}px) / ${SCREEN_DIFF}) * ${minMaxDiff}))`,
			},
		}
	}
	if (mq.large) {
		return {
			[unit]: `${max}px`,
		}
	}
}

function useVr(min, max, unit) {
	return useCss(generateCss(min, max, unit))
}

useVr.kids = (min, max, unit) => {
	return useCss({
		[`& > * + *`]: generateCss(min, max, unit),
	})
}

export default useVr
