import { React } from '@/vendor'
import { useToggle } from 'react-use'
import ReactDOM from 'react-dom'
import Fs from '@/components/Fs'
import Button from '@/components/Button'
import CSSTransition from '@/components/CSSTransition'
import { useCraft } from '@/components/Craft'
import styles from './styles.module.scss'

export const DOM_ID = 'form-dialog'

export function DialogWrapper() {
	return <div id="form-dialog" />
}

const transitionClassNames = {
	enter: styles.enter,
	exit: styles.exit,
	enterActive: styles.enterActive,
	exitActive: styles.exitActive,
}

export function Dialog(props) {
	const { settings } = useCraft()
	const [allow, toggleAllow] = useToggle()
	React.useEffect(() => {
		toggleAllow()
	}, [])
	if (!allow) {
		return null
	}
	return ReactDOM.createPortal(
		<CSSTransition in={props.in} classNames={transitionClassNames}>
			<div className={styles.dialog}>
				<CSSTransition in={props.in} classNames={transitionClassNames}>
					<div className={styles.dialog__overlay} />
				</CSSTransition>
				<div className={styles.dialog__body}>
					<Fs align="center">
						<Fs variant="fs5">Thank you</Fs>
						<p>
							Your message has been sent to {settings.propertyName}. We will get back to you within 48
							hours and during our business hours.
						</p>
						{Boolean(settings.applicationPdf) &&
							settings.applicationPdf.map(({ url }, idx) => {
								return (
									<p key={idx}>
										<a href={url} target="_blank">
											Download our application
										</a>
									</p>
								)
							})}
						<Button onClick={props.onClose} primary>
							Close
						</Button>
					</Fs>
				</div>
			</div>
		</CSSTransition>,
		document.getElementById('form-dialog'),
	)
}
