import { React, Classnames, Helmet } from '@/vendor'
import { AppWrapper, Fs, Nl2br, ImageTextGrid, Quotes, Button, Gallery } from '@/components'
import { useMq } from '@/components/Mq'
import Craft, { useCraft } from '@/components/Craft'
import styles from './styles.module.scss'

function getData() {
	const { homepage } = useCraft()
	return {
		...homepage,
		hero: homepage.hero[0],
	}
}

export default () => {
	const data = getData()
	const mq = useMq()
	return (
		<>
			<Helmet>
				<title>Welcome</title>
			</Helmet>
			<AppWrapper.Masthead dark className={Classnames(styles.masthead, { [styles.fancy]: !mq.small })}>
				<AppWrapper.Container className={styles.masthead__inner}>
					{data.hero.videoSrc ? (
						<video autoPlay loop muted playsInline className={styles.masthead__video}>
							<source src={data.hero.videoSrc} type="video/mp4" />
						</video>
					) : (
						<Craft.Img {...data.hero.image[0]} className={styles.masthead__image} />
					)}
					<div className={styles.masthead__headline}>
						<Fs tag="h1" variant="fs1" className={styles.masthead__h1}>
							<Nl2br text={data.hero.headline} />
						</Fs>
						<Button.Link to="/apartments" tertiary>
							Find an Apartment
						</Button.Link>
					</div>
				</AppWrapper.Container>
			</AppWrapper.Masthead>
			<AppWrapper.Body>
				<AppWrapper.Section>
					<AppWrapper.Container slim>
						<Fs align="center">
							<Fs variant="fs2">{data.introHeadline}</Fs>
							<Craft.RichText html={data.intro} />
							<Button.Link to="/apartments/neighborhood" primary className={styles.introCta}>
								View the Neighborhood
							</Button.Link>
						</Fs>
					</AppWrapper.Container>
				</AppWrapper.Section>
				<AppWrapper.Section>
					<AppWrapper.Container>
						<ImageTextGrid data={data.imageTextGrid} />
					</AppWrapper.Container>
				</AppWrapper.Section>
				<AppWrapper.Section>
					<AppWrapper.Container>
						<Quotes data={data.quotes} />
					</AppWrapper.Container>
				</AppWrapper.Section>
				{!!data.gallery?.length && (
					<AppWrapper.Section>
						<AppWrapper.Container>
							<Gallery data={data.gallery} />
						</AppWrapper.Container>
					</AppWrapper.Section>
				)}
			</AppWrapper.Body>
		</>
	)
}
