import { React, Classnames, Helmet } from '@/vendor'
import { AppWrapper, Amenities, Fs, Button, Grid, Gallery, VirtualTourLink } from '@/components'
import { useMq } from '@/components/Mq'
import Craft, { useCraft } from '@/components/Craft'
import { useParams, useNavigate, useOutlet, NavLink } from 'react-router-dom'
import styles from './styles.module.scss'

const Context = React.createContext({})

function Details() {
	const { id, title, bedrooms, bathrooms, squareFeet, rentPerMonth, intro, features, gallery, virtualTourLink } =
		React.useContext(Context)
	const wrapper = AppWrapper.useContext()
	const mq = useMq()
	return (
		<AppWrapper.Body>
			<AppWrapper.Container>
				<AppWrapper.Section>
					<div className={Classnames(styles.header, mq.small ? styles.stacked : styles.flex)}>
						<Fs className={styles.header__a}>
							<Fs tag="h1" variant="fs1" className={styles.title}>
								{title}
							</Fs>
							{bedrooms === 0 ? 'Studio' : `${bedrooms} bedroom`}, {bathrooms} bathroom
							<br />
							{squareFeet} sqft
						</Fs>
						<Fs align="center" className={styles.header__b}>
							<Fs variant="fs2" align="center">
								${rentPerMonth}
							</Fs>
							<Button
								secondary
								onClick={() => {
									wrapper.setApplyFormId(id)
								}}
							>
								Apply Now
							</Button>
						</Fs>
					</div>
					<Grid className={styles.intro}>
						<Grid.Cell>
							<Fs>
								<Craft.RichText html={intro} />
							</Fs>
						</Grid.Cell>
						<Grid.Cell flex={0}>
							<Fs>
								<ul className={styles.features}>
									{features.map(({ feature }, idx) => (
										<li key={idx}>{feature}</li>
									))}
								</ul>
							</Fs>
						</Grid.Cell>
					</Grid>
				</AppWrapper.Section>
				<AppWrapper.Section>
					<Gallery data={gallery} />
				</AppWrapper.Section>
			</AppWrapper.Container>
		</AppWrapper.Body>
	)
}

function ApartmentView(props) {
	const outlet = useOutlet()
	const { title, leadImage } = props
	return (
		<Context.Provider value={props}>
			<Helmet>
				<title>{props.title}</title>
			</Helmet>
			<AppWrapper.ScrollToTop />
			<AppWrapper.Masthead bgSrc={leadImage[0].url} className={styles.masthead} mask={false}>
				<AppWrapper.Masthead.Nav>
					<NavLink to="." end>
						Details
					</NavLink>
					<NavLink to="virtual-tour">Virtual Tour</NavLink>
					<NavLink to="amenities">Amenities</NavLink>
				</AppWrapper.Masthead.Nav>
			</AppWrapper.Masthead>
			{outlet || <Details />}
		</Context.Provider>
	)
}

function ApartmentSlugRoutes() {
	const navigate = useNavigate()
	const { slug } = useParams()
	const { apartments } = useCraft()
	const apartment = apartments.find((a) => a.slug === slug)
	React.useEffect(() => {
		if (!apartment) {
			navigate('/apartments')
		}
	}, [apartment])
	if (apartment) {
		return <ApartmentView {...apartment} />
	}
	return null
}

ApartmentSlugRoutes.VirtualTour = () => {
	const { virtualTourLink } = React.useContext(Context)
	return (
		<AppWrapper.Body>
			<AppWrapper.Container>
				<AppWrapper.Section>
					<iframe
						allow="gyroscope; accelerometer;"
						src={virtualTourLink}
						frameBorder="0"
						bgcolor="transparent"
						target="_blank"
						allowFullScreen
						webkitallowfullscreen="webkitallowfullscreen"
						mozallowfullscreen="mozallowfullscreen"
						className={styles.virtualTourEmbed}
					/>
				</AppWrapper.Section>
			</AppWrapper.Container>
		</AppWrapper.Body>
	)
}

ApartmentSlugRoutes.Amenities = Amenities

export default ApartmentSlugRoutes
