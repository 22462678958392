import { React } from '@/vendor'

export default (props) => (
	<svg width={66} height={59} viewBox="0 0 66 59" {...props}>
		<path
			d="M42.25 26.149l7.188 6.9-3.177 3.437h1.193c4.821 0 7.232 2.034 7.232 6.1l-43.868.29c-.522-4.26 1.96-6.39 7.445-6.39h1.656l-4.02-3.438 7.351-6.9 7.188 6.9-3.177 3.438h11.658l-4.02-3.438 7.351-6.9z"
			fill="#0CC493"
			opacity={0.3}
		/>
		<path d="M51.641 21.202c4.075 0 7.392 3.317 7.392 7.391v13.662h1.22c.423 0 .764.34.764.763l-.007 9.305c0 .422-.34.763-.763.763h-4.041v4.118h2.67v1.526H6.1v-1.526h2.973v-4.118H5.03a.762.762 0 01-.763-.763v-9.307c0-.423.341-.764.763-.764h1.221V28.593c0-4.074 3.317-7.39 7.392-7.39zm13.646 36.001v1.527h-4.27v-1.527h4.27zm-61.474 0v1.527H0v-1.527h3.813zm9.078-4.118H10.6v4.118h2.287l.003-4.118zm37.982 0H14.414v4.118h36.46v-4.118zm3.813 0h-2.29v4.118h2.29v-4.118zm4.807-3.357H5.797v1.831H59.49l.003-1.831zm0-5.947H5.797v4.423H59.49l.003-4.423zm-7.852-21.053H13.643a5.87 5.87 0 00-5.865 5.865v13.662h2.15v-1.254c0-2.924 3.26-5.303 7.268-5.303h.292l-1.494-1.493a1.503 1.503 0 010-2.12l6.26-6.26c.284-.284.66-.44 1.06-.44.401 0 .777.156 1.06.44l6.26 6.26c.284.283.44.659.44 1.06 0 .4-.156.776-.44 1.06l-1.49 1.49h6.846l-1.34-1.34a1.503 1.503 0 010-2.121l6.26-6.26a1.499 1.499 0 012.12 0l6.26 6.26a1.503 1.503 0 010 2.12l-1.341 1.341h.139c4.008 0 7.268 2.38 7.268 5.304v1.253h2.15V28.593a5.87 5.87 0 00-5.865-5.865zM27.6 37.221H17.197c-3.113 0-5.743 1.73-5.743 3.78v1.254h42.378v-1.254c0-2.05-2.63-3.78-5.742-3.78H27.62l-.01.011-.011-.01zm14.37-10.149l-6.221 6.222 2.4 2.401 7.645.003 2.401-2.401-6.224-6.225zm-18.654-.152l-6.222 6.222 2.554 2.553h7.342l2.55-2.55-6.224-6.225zM45.905 0c.422 0 .763.34.763.763l.003 14.032c0 .423-.341.764-.764.764H25.004l-.043.076-.134-.076h-5.445a.762.762 0 01-.763-.764V.763c0-.422.34-.763.763-.763zM36.57 1.526H20.145v12.509h3.98l3.158-5.472 1.322.763-2.72 4.709h9.696l4.745-8.216 1.322.763-4.304 7.454h7.798V1.526h-6.81l-3.023 5.236-1.322-.764 2.584-4.472zM28.82 5.894l1.321.763-1.087 1.884-1.322-.763 1.088-1.884z" />
	</svg>
)
