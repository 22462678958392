import { React, Helmet } from '@/vendor'

export default () => (
	<>
		<Helmet>
			<title>Page not Found</title>
		</Helmet>
		Page not found
	</>
)
