import { React } from '@/vendor'
import { CSSTransition } from 'react-transition-group'

export default (props) => (
	<CSSTransition
		mountOnEnter
		unmountOnExit
		addEndListener={(node, done) => {
			node.addEventListener('transitionend', done, false)
		}}
		{...props}
	/>
)

// <CSSTransition
// 	in={this.state.videoActive}
// 	classNames={{
// 		enter: styles.enter,
// 		enterActive: styles.enterActive,
// 		exitActive: styles.exitActive
// 	}}
// 	children={
// 		<OverlayVideo
// 			onClose={() => {
// 				this.toggleVideo(false)
// 			}}
// 		/>
// 	}
// />
