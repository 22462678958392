import { React, Helmet } from '@/vendor'
import { Routes, Route, NavLink, Link, Navigate, useOutlet } from 'react-router-dom'
import { AppWrapper, Craft, Amenities, Neighborhood, Availability, ApartmentFinder } from '@/components'
import styles from './styles.module.scss'

const ApartmentsRoutes = {}

ApartmentsRoutes.Index = () => {
	const outlet = useOutlet()
	const { settings } = React.useContext(Craft.Context)
	if (outlet) {
		return (
			<>
				<Helmet>
					<title>Apartments</title>
				</Helmet>
				<AppWrapper.ScrollToTop />
				<AppWrapper.Masthead>
					<AppWrapper.Masthead.Basic headline={settings.propertyName} />
					<AppWrapper.Masthead.Nav>
						<NavLink to="availability">Availability</NavLink>
						<NavLink to="amenities">Amenities</NavLink>
						<NavLink to="neighborhood">Neighborhood</NavLink>
					</AppWrapper.Masthead.Nav>
				</AppWrapper.Masthead>
				{outlet}
			</>
		)
	} else {
		return (
			<>
				<Helmet>
					<title>Find an apartment</title>
				</Helmet>
				<AppWrapper.ScrollToTop />
				<AppWrapper.Masthead dark className={styles.wizardMasthead}>
					<AppWrapper.Container>
						<ApartmentFinder />
					</AppWrapper.Container>
				</AppWrapper.Masthead>
			</>
		)
	}
}

ApartmentsRoutes.Availability = Availability
ApartmentsRoutes.Amenities = () => (
	<>
		<Helmet>
			<title>Amenities</title>
		</Helmet>
		<Amenities />
	</>
)
ApartmentsRoutes.Neighborhood = () => (
	<>
		<Helmet>
			<title>Neighborhood</title>
		</Helmet>
		<Neighborhood />
	</>
)

export default ApartmentsRoutes
