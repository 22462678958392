import { React, PropTypes, Classnames, Link } from '@/vendor'
import styles from './styles.module.scss'

function Button({ tag: Tag = 'button', primary, secondary, tertiary, className, ...props }) {
	const styleClassName = (() => {
		if (primary) {
			return styles.primary
		}
		if (secondary) {
			return styles.secondary
		}
		if (tertiary) {
			return styles.tertiary
		}
	})()
	return <Tag className={Classnames(styles.button, styleClassName, className)} {...props} />
}

Button.propTypes = {
	tag: PropTypes.elementType,
	className: PropTypes.string,
}

Button.Link = (props) => {
	const Tag = 'to' in props ? Link : 'a'
	return <Button tag={Tag} {...props} />
}

export default Button
