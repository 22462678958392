import { React } from '@/vendor'

export default (props) => (
	<svg width={59} height={60} viewBox="0 0 59 60" {...props}>
		<path
			d="M50.999 33.1a1.494 1.494 0 11-1.93 1.977 1.49 1.49 0 01.304-1.634A1.499 1.499 0 0151 33.1zm-.103 1.187l-.912.398a.499.499 0 00.935-.34l-.023-.058zm-.65-.258a.497.497 0 00-.289.566l.027.085.912-.393-.008-.02a.49.49 0 00-.043-.071l-.056-.065a.495.495 0 00-.543-.102zm-36.203-2.034v1.994h-1.995v-1.994h1.995zm3.989 0v1.994h-1.995v-1.994h1.995zm3.99 0v1.994h-1.995v-1.994h1.994zm3.989 0v1.994h-1.995v-1.994h1.995zm11.025-1.888a1.497 1.497 0 01-.534 2.885 1.495 1.495 0 11.534-2.885zm-.758.906a.5.5 0 00-.254.675c.052.122.152.22.274.27a.499.499 0 00.643-.66.5.5 0 00-.663-.285zm-22.235-3.008V30h-1.995v-1.995h1.995zm3.989 0V30h-1.995v-1.995h1.995zm3.99 0V30h-1.995v-1.995h1.994zm3.989 0V30h-1.995v-1.995h1.995zm15.279-2.747a1.498 1.498 0 01-.818 2.747 1.496 1.496 0 11.818-2.748zm-.933.766l-.085.027a.517.517 0 00-.266.271.496.496 0 00.277.65.5.5 0 00.383-.004.5.5 0 00-.309-.944zm-26.314-2.008v1.995h-1.995v-1.995h1.995zm3.989 0v1.995h-1.995v-1.995h1.995zm3.99 0v1.995h-1.995v-1.995h1.994zm3.989 0v1.995h-1.995v-1.995h1.995zm28.922-1.995c0 2.66-3.99 2.66-3.99 0s3.99-2.66 3.99 0zm-40.89-1.994v1.994h-1.995v-1.994h1.995zm3.989 0v1.994h-1.995v-1.994h1.995zm3.99 0v1.994h-1.995v-1.994h1.994zm3.989 0v1.994h-1.995v-1.994h1.995zm19.946-2.992c0 2.66-3.989 2.66-3.989 0s3.99-2.66 3.99 0zm-23.936-.998v1.995h-1.994v-1.995h1.994zm3.99 0v1.995h-1.995v-1.995h1.995zm-7.98 0v1.995h-1.994v-1.995h1.995zm-3.988 0v1.995h-1.995v-1.995h1.995zm7.978-3.99v1.996h-1.994v-1.995h1.994zm-3.99 0v1.996h-1.994v-1.995h1.995zm-3.988 0v1.996h-1.995v-1.995h1.995zm11.968 0v1.996h-1.995v-1.995h1.995zm10.24-4.255a2.493 2.493 0 11-3.525 3.527 2.493 2.493 0 013.525-3.527zm-1.763 1.264a.5.5 0 00-.192.96.495.495 0 00.543-.11.495.495 0 00-.351-.85zM47.078.955a2.993 2.993 0 11-4.234 4.232A2.993 2.993 0 0147.077.955zm-2.118 1.12a.997.997 0 100 1.994.997.997 0 000-1.995z"
			fill="#0CC493"
			fillRule="nonzero"
			opacity={0.3}
		/>
		<path d="M15.008 57.873l2.002.053v2.05h-2.002v-2.103zm31.947-.127v2.174H44.96v-1.994l1.995-.18zM15.04.08a4.992 4.992 0 014.987 4.987v.997h2.992a2.991 2.991 0 012.992 2.992v1.995h-1.995V9.056a.998.998 0 00-.997-.997H15.04a.997.997 0 00-.997.997v1.995h-1.995V9.056a2.991 2.991 0 012.992-2.992h2.992v-.998a2.991 2.991 0 00-2.992-2.992H5.067a2.992 2.992 0 00-2.992 2.993v27.926h.997v-.998h1.995v3.99H3.072v-.998h-.997v24.934H.08V5.067A4.992 4.992 0 015.067.08zm39.893 39.893v5.984a9.98 9.98 0 01-2.924 7.05 9.98 9.98 0 01-7.05 2.924H16.038a9.98 9.98 0 01-7.048-2.925 9.98 9.98 0 01-2.925-7.049v-5.984h48.87zm-1.994 1.995H41.97v1.995h10.97v-1.995zm-12.966 0H37.98v1.995h1.994v-1.995zm-3.989 0H8.059v1.995h27.925v-1.995zM4.069 39.973v1.995h-.997a.997.997 0 110-1.995h.997zm53.857 0a.997.997 0 110 1.995h-.998v-1.995z" />
	</svg>
)
