import { React, Classnames } from '@/vendor'
import { useCss } from 'react-use'
import * as glyphs from './glyphs/*.js'

const Sprite = ({ name, className, rotate, ...props }) => {
	const rotateClassName = useCss({
		transform: `rotate(${rotate}deg)`,
	})
	const Svg = glyphs[name]
	const classNames = Classnames(className, {
		[rotateClassName]: !!rotate,
	})
	return <Svg className={classNames} {...props} />
}

export default Sprite
