import { React } from '@/vendor'
import { useMq } from '@/components/Mq'
import Grid from '@/components/Grid'
import Fs from '@/components/Fs'
import styles from './styles.module.scss'

function TwoColGrid({ lhs, rhs }) {
	const mq = useMq()
	return (
		<Grid stacked={mq.small} vr={[24, 24]} className={styles.twocol__grid}>
			<Grid.Cell className={styles.twocol__cell}>{lhs}</Grid.Cell>
			<Grid.Cell className={styles.twocol__cell}>{rhs}</Grid.Cell>
		</Grid>
	)
}

TwoColGrid.HeadlineText = ({ headline, children }) => (
	<Fs align="center">
		<Fs tag="h3" variant="fs3">
			{headline}
		</Fs>
		{children}
	</Fs>
)

export default TwoColGrid
