import { React, Link } from '@/vendor'
import Sprite from '@/components/Sprite'
import styles from './styles.module.scss'

function VirtualTourLink(props) {
	return (
		<Link className={styles.virtualTourLink} {...props}>
			<Sprite name="tour" />
			Virtual Tour
		</Link>
	)
}

export default VirtualTourLink
