import { React, Classnames } from '@/vendor'
import { useCss } from 'react-use'
import Craft from '@/components/Craft'
import Grid from '@/components/Grid'
import Fs from '@/components/Fs'
import TinySlider from '@/components/TinySlider'
import styles from './styles.module.scss'

const SLIDER_OPTIONS = {
	autoplay: false,
	autoHeight: true,
}

function Quote({ quote }) {
	return <Craft.RichText html={quote} className={styles.quote} />
}

function Quotes({ data }) {
	return (
		<div className={styles.outer}>
			<TinySlider options={SLIDER_OPTIONS}>
				{data.map(({ id, quote }) => (
					<TinySlider.Slide key={id}>
						<Quote quote={quote} />
					</TinySlider.Slide>
				))}
			</TinySlider>
		</div>
	)
}

export default Quotes
