import { React, Classnames, Link } from '@/vendor'
import { useNumber, useRaf } from 'react-use'
import { Formik, Form, useField, useFormikContext } from 'formik'
import { TransitionGroup } from 'react-transition-group'
import { Navigate } from 'react-router-dom'
import CSSTransition from '@/components/CSSTransition'
import Fs from '@/components/Fs'
import Sprite from '@/components/Sprite'
import Button from '@/components/Button'
import { useCraft } from '@/components/Craft'
import { filter, groupBy, isNil, omitBy } from 'lodash'
import styles from './styles.module.scss'

function Option({ name, value, ...props }) {
	const [field, meta, { setValue }] = useField(name)
	return (
		<Button
			primary
			onClick={() => {
				setValue(value)
			}}
			{...props}
		/>
	)
}

function Step({ title, icon, options }) {
	return (
		<div className={styles.step}>
			<Sprite name={icon} className={styles.step__icon} />
			<Fs tag="h2" variant="fs5">
				{title}
			</Fs>
			<div className={styles.step__options}>{options}</div>
		</div>
	)
}

function FoundMatch({ headline, to }) {
	const raf = useRaf(3000)
	const percent = raf * 100
	const done = raf === 1
	const remainingSeconds = Math.abs(Math.floor(3 * raf) - 3)
	if (raf === 1) {
		return <Navigate to={to} />
	}
	return (
		<div className={Classnames(styles.step, styles.foundMatch)}>
			<Fs align="center">
				Congrats!
				<Fs tag="h2" variant="fs5">
					{headline}
				</Fs>
				<div className={styles.progressBar}>
					<div className={styles.progressBar__bar} style={{ width: `${percent}%` }} />
				</div>
				<div className={styles.remaining}>Sending you in {remainingSeconds}...</div>
			</Fs>
		</div>
	)
}

Step.Final = () => {
	const { values } = useFormikContext()
	const { apartments } = useCraft()
	const cleanValues = omitBy(values, isNil)
	const filtered = filter(apartments, cleanValues)
	const match = filtered.length === 1 ? filtered[0] : null
	if (match) {
		return <FoundMatch headline="We found a match" to={`/apartments/${match.slug}`} />
	}
	if (filtered.length) {
		return (
			<FoundMatch
				headline={`We found ${filtered.length} matches`}
				to={`/apartments/availability?bedrooms=${values.bedrooms}&bathrooms=${values.bathrooms}`}
			/>
		)
	}
	return <FoundMatch headline={`Taking you to our available apartments`} to={`/apartments/availability`} />
}

function FormikSync({ setState }) {
	const { values } = useFormikContext()
	const valuesString = JSON.stringify(values)
	React.useLayoutEffect(() => {
		setState(values)
	}, [valuesString])
	return null
}

function ApartmentFinder() {
	const { apartments } = useCraft()
	const [stepIdx, { inc: incrementStep }] = useNumber(0)
	const [formikState, setFormikState] = React.useState({})
	const bedrooms = Object.keys(groupBy(apartments, 'bedrooms'))
	const filtered = apartments.filter((a) => {
		if (!isNil(formikState.bedrooms)) {
			return a.bedrooms === formikState.bedrooms
		}
		return true
	})
	const bathrooms = Object.keys(groupBy(filtered, 'bathrooms'))
	const stepsWrap = (
		<>
			<Step
				title="How many bedrooms would you like?"
				icon="bedrooms"
				options={
					<>
						{bedrooms.map((bedroom) => (
							<Option name="bedrooms" value={+bedroom} key={bedroom}>
								{bedroom === '0' ? 'Studio' : bedroom}
							</Option>
						))}
					</>
				}
			/>
			{bathrooms.length > 1 && (
				<Step
					title="And how many bathrooms?"
					icon="bathrooms"
					options={
						<>
							{bathrooms.map((bathroom) => (
								<Option name="bathrooms" value={+bathroom} key={bathroom}>
									{bathroom}
								</Option>
							))}
						</>
					}
				/>
			)}
			<Step.Final />
		</>
	)
	const steps = React.Children.toArray(stepsWrap.props.children)
	const isFinal = stepIdx == steps.length - 1
	const onSubmit = () => {
		incrementStep()
	}
	const transitionClassNames = {
		enter: styles.enter,
		exit: styles.exit,
		enterActive: styles.enterActive,
		exitActive: styles.exitActive,
	}
	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapper__upper}>
				<Formik initialValues={{ bedrooms: null, bathrooms: null }} onSubmit={onSubmit}>
					<Form>
						<FormikSync setState={setFormikState} />
						<TransitionGroup>
							<CSSTransition key={stepIdx} classNames={transitionClassNames}>
								{steps[stepIdx]}
							</CSSTransition>
						</TransitionGroup>
					</Form>
				</Formik>
			</div>
			{!isFinal && (
				<div className={styles.wrapper__lower}>
					<Link to="/apartments/availability">Skip, and show me all available apartments</Link>
				</div>
			)}
		</div>
	)
}

export default ApartmentFinder
