import { AppWrapper, Nl2br, TwoColGrid } from '@/components'
import { useCraft } from '@/components/Craft'
import TelLink from '@/components/TelLink'
import { Helmet, React } from '@/vendor'

export default () => {
	const { settings } = useCraft()
	return (
		<>
			<Helmet>
				<title>Contact</title>
			</Helmet>
			<AppWrapper.ScrollToTop />
			<AppWrapper.FixedContact />
			<AppWrapper.Masthead>
				<AppWrapper.Masthead.Basic headline="Contact Us" />
			</AppWrapper.Masthead>
			<AppWrapper.Banner>
				<TwoColGrid
					lhs={
						<TwoColGrid.HeadlineText headline="Office Location">
							<Nl2br text={settings.propertyAddress} />
							T. <TelLink number={settings.propertyPhoneNumber} />
							<br />
							F. {settings.propertyFaxNumber}
						</TwoColGrid.HeadlineText>
					}
					rhs={
						<TwoColGrid.HeadlineText headline="Office Hours">
							<Nl2br text={settings.propertyOperationHours} />
						</TwoColGrid.HeadlineText>
					}
				/>
			</AppWrapper.Banner>
		</>
	)
}
