import { React, Classnames, Link } from '@/vendor'
import AppWrapper from '@/components/AppWrapper'
import Grid from '@/components/Grid'
import Craft from '@/components/Craft'
import Fs from '@/components/Fs'
import Button from '@/components/Button'
import VirtualTourLink from '@/components/VirtualTourLink'
import { useMq } from '@/components/Mq'
import styles from './styles.module.scss'

function Listing({ id, title, slug, leadImage, bedrooms, bathrooms, squareFeet, rentPerMonth, virtualTourLink }) {
	leadImage = leadImage[0]
	const wrapper = AppWrapper.useContext()
	const mq = useMq()
	const Linked = React.useMemo(() => (props) => <Link to={`/apartments/${slug}`} {...props} />, [slug])
	return (
		<div className={Classnames(styles.listing, mq.small ? styles.stacked : styles.flex)}>
			<div className={styles.leadImage}>
				<Linked>
					<Craft.Img url={leadImage.thumb} width={830} height={632} />
				</Linked>
			</div>
			<div className={styles.primary}>
				<Fs className={styles.primary__a}>
					<Fs tag={Linked} variant="fs2" className={styles.primary__title}>
						{title}
					</Fs>
					<br />
					{bedrooms === 0 ? 'Studio' : `${bedrooms} bedroom`}, {bathrooms} bathroom
					<br />
					{squareFeet} sqft
				</Fs>
				<div className={styles.primary__b}>
					<Fs align="center">
						<Fs variant="fs2" align="center">
							${rentPerMonth}
						</Fs>
						<div className={styles.primary__cta}>
							<Button
								secondary
								onClick={() => {
									wrapper.setApplyFormId(id)
								}}
							>
								Apply
							</Button>
							<Button tag={Linked} primary>
								View
							</Button>
						</div>
						{virtualTourLink && <VirtualTourLink to={`/apartments/${slug}/virtual-tour`} />}
					</Fs>
				</div>
			</div>
		</div>
	)
}

function Listings({ data }) {
	return (
		<div className={styles.listings}>
			{data.map((listing) => (
				<Listing {...listing} key={listing.id} />
			))}
		</div>
	)
}

export default Listings
