import { React } from '@/vendor'
import AppWrapper from '@/components/AppWrapper'
import TwoColGrid from '@/components/TwoColGrid'
import Craft, { useCraft } from '@/components/Craft'
import Fs from '@/components/Fs'
import Nl2br from '@/components/Nl2br'
import ImageTextGrid from '@/components/ImageTextGrid'

function Amenities() {
	const { amenities } = useCraft()
	return (
		<AppWrapper.Body>
			<AppWrapper.Section>
				<AppWrapper.Container slim>
					<Fs variant="fs4" align="center">
						<Craft.RichText html={amenities.intro} />
					</Fs>
				</AppWrapper.Container>
			</AppWrapper.Section>
			<AppWrapper.Section>
				<AppWrapper.Container>
					<TwoColGrid
						lhs={
							<Fs align="center">
								<Fs tag="h2" variant="fs6">
									Resident responsibility
								</Fs>
								<Fs variant="fs4">
									<Nl2br text={amenities.residentResponsibility} />
								</Fs>
							</Fs>
						}
						rhs={
							<Fs align="center">
								<Fs tag="h2" variant="fs6">
									Fee-based amenities
								</Fs>
								<Fs variant="fs4">
									<Nl2br text={amenities.feeBasedAmenities} />
								</Fs>
							</Fs>
						}
					/>
				</AppWrapper.Container>
			</AppWrapper.Section>
			<AppWrapper.Section>
				<AppWrapper.Container>
					<ImageTextGrid data={amenities.imageTextGrid} />
				</AppWrapper.Container>
			</AppWrapper.Section>
		</AppWrapper.Body>
	)
}

export default Amenities
