import { React } from '@/vendor'
import { useSearchParams } from 'react-router-dom'
import AppWrapper from '../AppWrapper'
import ApartmentListings from '../ApartmentListings'
import { useCraft } from '../Craft'
import { filter, omitBy, isNil } from 'lodash'

function Availability() {
	const { apartments } = useCraft()
	const [params] = useSearchParams()
	const bedrooms = +params.get('bedrooms') || null
	const bathrooms = +params.get('bathrooms') || null
	const criteria = omitBy({ bedrooms, bathrooms }, isNil)
	const filtered = filter(apartments, criteria)
	return (
		<AppWrapper.Body>
			<AppWrapper.Section>
				<AppWrapper.Container>
					<ApartmentListings data={filtered} />
				</AppWrapper.Container>
			</AppWrapper.Section>
		</AppWrapper.Body>
	)
}

export default Availability
