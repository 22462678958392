import { React, Helmet } from '@/vendor'
import { Mq, Craft, PageChangeListener, AppLoading, AppWrapper } from '@/components'
import { DialogWrapper } from '@/components/Form/Dialog'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { get } from 'lodash'

// Routes
import PageNotFoundRoute from '@/routes/404'
import HomepageRoute from '@/routes/homepage'
import ApartmentsRoutes from '@/routes/apartments'
import ApartmentSlugRoutes from '@/routes/apartments/:slug'
import ResidentsRoute from '@/routes/residents'
import ContactRoute from '@/routes/contact'

const CRAFT_URL = `https://majormanagement.craftcms.me/api`

const QUERY_VARS = {
	site: process.env.APP,
}

class App extends React.PureComponent {
	state = {
		overlayNavActive: false,
	}

	get breakpoints() {
		return {
			small: 'screen and (max-width: 767px)',
			medium: 'screen and (min-width: 768px) and (max-width: 999px)',
			large: 'screen and (min-width: 1000px)',
		}
	}

	render() {
		return (
			<Craft.Provider apiUrl={CRAFT_URL} query={require('./craft.gql')} variables={QUERY_VARS}>
				{({ loading, settings }) => (
					<Mq.Provider config={this.breakpoints}>
						<BrowserRouter>
							<PageChangeListener
								callback={() => {
									window._gaq.push(['_trackPageview'])
									this.setState({
										overlayNavActive: false,
									})
								}}
							/>
							<Craft.Logger enabled={process.env.NODE_ENV !== 'production'} />
							{loading ? (
								<AppLoading />
							) : (
								<>
									<Helmet titleTemplate={`%s | ${settings.propertyName}`} />
									<AppWrapper>
										<Routes>
											<Route path="*" element={<PageNotFoundRoute />} />
											<Route path="/" element={<HomepageRoute />} />
											<Route path="/apartments" element={<ApartmentsRoutes.Index />}>
												<Route
													path="/availability"
													element={<ApartmentsRoutes.Availability />}
												/>
												<Route path="/amenities" element={<ApartmentsRoutes.Amenities />} />
												<Route
													path="/neighborhood"
													element={<ApartmentsRoutes.Neighborhood />}
												/>
											</Route>
											<Route path="/apartments/:slug" element={<ApartmentSlugRoutes />}>
												<Route
													path="/virtual-tour"
													element={<ApartmentSlugRoutes.VirtualTour />}
												/>
												<Route path="/amenities" element={<ApartmentSlugRoutes.Amenities />} />
											</Route>
											<Route path="/residents" element={<ResidentsRoute />} />
											<Route path="/contact" element={<ContactRoute />} />
										</Routes>
									</AppWrapper>
									<DialogWrapper />
								</>
							)}
						</BrowserRouter>
					</Mq.Provider>
				)}
			</Craft.Provider>
		)
	}
}

export default App
